@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
	@font-face {
		font-family: "SourceSansProBlack";
		src: url("../src/assets/fonts/source-sans-pro/SourceSansPro-Black.ttf");
	}
	@font-face {
		font-family: "SourceSansProBold";
		src: url("../src/assets/fonts/source-sans-pro/SourceSansPro-Bold.ttf");
	}
	@font-face {
		font-family: "SourceSansLight";
		src: url("../src/assets/fonts/source-sans-pro/SourceSansPro-Light.ttf");
	}
	@font-face {
		font-family: "SourceSansProExtraLight";
		src: url("../src/assets/fonts/source-sans-pro/SourceSansPro-ExtraLight.ttf");
	}
	@font-face {
		font-family: "SourceSansProRegular";
		src: url("../src/assets/fonts/source-sans-pro/SourceSansPro-Regular.ttf");
	}
}
@layer components {
	.bg-highContrast-background {
		@apply bg-white;
	}

	.text-highContrast-text {
		@apply text-black;
	}
}



body {
	// @apply font-sans bg-highContrast-background text-highContrast-text;
	@apply bg-highContrast-background text-highContrast-text;
}

/* Migliora la visibilità del focus */
:focus {
	@apply outline-none ring-2 ring-highContrast-primary;
}

/* Stili per il contrasto di testo e link */
a {
	@apply text-highContrast-primary underline;
}

a:hover,
a:focus {
	@apply text-highContrast-secondary;
}

/* Fornisci indicatori visivi per la navigazione */
nav {
	@apply flex flex-wrap items-center justify-between;
}

nav a {
	@apply text-highContrast-accent hover:text-highContrast-primary;
}